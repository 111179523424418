import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TaxiServiceInterface} from "../interfaces/taxi-service.interface";
import {UserRegistrationInterface} from "../interfaces/user-registration.interface";
import {BrandInterface} from "../interfaces/brand.interface";
import {ModelInterface} from "../interfaces/model.interface";
import {CountryInterface} from "../interfaces/country.interface";
import {BodyTypeInterface} from "../interfaces/body-type.interface";
import {ColorInterface} from "../interfaces/color.interface";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  public userRegistrationData$: BehaviorSubject<UserRegistrationInterface> = new BehaviorSubject<UserRegistrationInterface>(null);
  public taxiServices$: BehaviorSubject<TaxiServiceInterface[]> = new BehaviorSubject<TaxiServiceInterface[]>(null);
  public carBrands$: BehaviorSubject<BrandInterface[]> = new BehaviorSubject<BrandInterface[]>(null);
  public carModels$: BehaviorSubject<ModelInterface[]> = new BehaviorSubject<ModelInterface[]>(null);
  public countries$: BehaviorSubject<CountryInterface[]> = new BehaviorSubject<CountryInterface[]>(null);
  public bodyTypes$: BehaviorSubject<BodyTypeInterface[]> = new BehaviorSubject<BodyTypeInterface[]>(null);
  public colors$: BehaviorSubject<ColorInterface[]> = new BehaviorSubject<ColorInterface[]>(null);




  public phoneRegistrationForm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public nameRegistrationForm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public personalRegistrationForm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public carRegistrationForm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public countryRegistrationForm$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public previousSelectedBrandId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public verificationCode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor() { }

  public clearAllData(): void {
  this.userRegistrationData$.next(null)
  this.carBrands$.next(null)
  this.carModels$.next(null)
  this.countries$.next(null)
  this.bodyTypes$.next(null)
  this.colors$.next(null)
  this.phoneRegistrationForm$.next(null)
  this.nameRegistrationForm$.next(null)
  this.personalRegistrationForm$.next(null)
  this.carRegistrationForm$.next(null)
  this.countryRegistrationForm$.next(null)
  this.previousSelectedBrandId$.next(null)
  this.verificationCode$.next(null)
  }



}
