import {IconRegistryInterface} from "../interfaces/photo.interface";

export const ICON_REGISTRY_LIGHT: IconRegistryInterface[] = [
  {
    name: 'utax-suc',
    icon: `<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8237_39387)">
<rect x="6" y="6.5" width="52" height="52" rx="26" fill="#0FC659"/>
<path d="M23 31.06L29.4987 38.5L41 26.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
<rect x="6" y="6.5" width="52" height="52" rx="26" stroke="#B7EECD" stroke-width="12"/>
</g>
<defs>
<clipPath id="clip0_8237_39387">
<rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
` },
  {
    name: 'utax-rej',
    icon: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8237_39215)">
<rect x="6" y="6" width="52" height="52" rx="26" fill="#F33F4D"/>
<path d="M39 25L25 39" stroke="white" stroke-width="3" stroke-linecap="round"/>
<path d="M25 25L39 39" stroke="white" stroke-width="3" stroke-linecap="round"/>
<rect x="6" y="6" width="52" height="52" rx="26" stroke="#FBC5CA" stroke-width="12"/>
</g>
<defs>
<clipPath id="clip0_8237_39215">
<rect width="64" height="64" fill="white"/>
</clipPath>
</defs>
</svg>
` },
  {
    name: 'utax-process',
    icon: `<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8237_39301)">
<rect x="6" y="6.5" width="52" height="52" rx="26" fill="#B7EECD"/>
<rect x="6" y="6.5" width="52" height="52" rx="26" stroke="#B7EECD" stroke-width="12"/>
<path d="M25.2727 23.5337C22.5513 25.5783 20.791 28.8332 20.791 32.4992C20.791 38.6886 25.8085 43.7061 31.9979 43.7061C37.7322 43.7061 42.4606 39.3994 43.1249 33.844" stroke="#0FC659" stroke-width="2" stroke-linecap="round"/>
<path d="M32 21.2925C36.0549 21.2925 39.6069 23.446 41.5745 26.6718" stroke="#0FC659" stroke-width="2" stroke-linecap="round"/>
</g>
<defs>
<clipPath id="clip0_8237_39301">
<rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
` }
];
export const ICON_REGISTRY_DARK: IconRegistryInterface[] = [
  {
    name: 'utax-suc',
    icon: `<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8246_39244)">
<rect x="6" y="6.5" width="52" height="52" rx="26" fill="#0FC659"/>
<path d="M23 31.06L29.4987 38.5L41 26.5" stroke="#111416" stroke-width="3" stroke-linecap="round"/>
<rect x="6" y="6.5" width="52" height="52" rx="26" stroke="#1B5A36" stroke-width="12"/>
</g>
<defs>
<clipPath id="clip0_8246_39244">
<rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
` },
  {
    name: 'utax-rej',
    icon: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8246_39072)">
<rect x="6" y="6" width="52" height="52" rx="26" fill="#F33F4D"/>
<path d="M39 25L25 39" stroke="#111416" stroke-width="3" stroke-linecap="round"/>
<path d="M25 25L39 39" stroke="#111416" stroke-width="3" stroke-linecap="round"/>
<rect x="6" y="6" width="52" height="52" rx="26" stroke="#3F1D21" stroke-width="12"/>
</g>
<defs>
<clipPath id="clip0_8246_39072">
<rect width="64" height="64" fill="white"/>
</clipPath>
</defs>
</svg>
` },
  {
    name: 'utax-process',
    icon: `<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8246_39158)">
<rect x="6" y="6.5" width="52" height="52" rx="26" fill="#1B5A36"/>
<rect x="6" y="6.5" width="52" height="52" rx="26" stroke="#1B5A36" stroke-width="12"/>
<path d="M25.2727 23.5337C22.5513 25.5783 20.791 28.8332 20.791 32.4992C20.791 38.6886 25.8085 43.7061 31.9979 43.7061C37.7322 43.7061 42.4606 39.3994 43.1249 33.844" stroke="#0FC659" stroke-width="2" stroke-linecap="round"/>
<path d="M32 21.2925C36.0549 21.2925 39.6069 23.446 41.5745 26.6718" stroke="#0FC659" stroke-width="2" stroke-linecap="round"/>
</g>
<defs>
<clipPath id="clip0_8246_39158">
<rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
` }
];
