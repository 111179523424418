import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public driverAppId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public fireBaseToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }
}
