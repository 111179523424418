import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import {TranslateModule, TranslateLoader, MissingTranslationHandler} from '@ngx-translate/core';
import { TranslateLSLoader } from './translate-ls-loader';

import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeUa from '@angular/common/locales/uk';
import {TranslateHandler} from "./translate-handler";

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateLSLoader(http);
        },
        deps: [HttpClient],
      },
      missingTranslationHandler: [{provide: MissingTranslationHandler, useClass: TranslateHandler}]
    })
  ],
  exports: [
    TranslateModule
  ]
})
export class AppTranslateModule {
  constructor() {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeRu, 'ru');
    registerLocaleData(localeUa, 'ua');
  }
}
