import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {TokenService} from "../services/token.service";
import {RegistrationService} from "../modules/registration/services/registration.service";

@Injectable()
export class BuildRequestInterceptor implements HttpInterceptor {

  // private driverAppId = '747174e2-fca7-42ba-b9ac-262bb9b137f2'
  private driverAppId: string = '';

  constructor(private tokenService: TokenService, private registrationService: RegistrationService) {
    this.tokenService.driverAppId.subscribe(id => this.driverAppId = id);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('X-Application-Id') && this.driverAppId) {
      request = request.clone({
        headers: request.headers.set('X-Application-Id', this.driverAppId).set('Accept-Language', 'uk-UA')
      });
    }
    if(request) {
      request = request.clone({
        url: `${this.selectBaseUrl(request.url)}/${request.url}`
      });
    }
    if(!request.headers.has('Authorization') &&
      request.url.includes('driver-registration/api/driver') &&
      !request.url.includes('driver-registration/api/driver/tokens')) {
        request = request.clone({
          headers: request.headers.set('Authorization', this.authHeader())
        });
    }


    return next.handle(request).pipe(
      catchError((err) => {
        if(err.status === 401 && !request.url.includes('/api/driver/tokens/issue')) {
          this.registrationService.clearAllData();
          this.tokenService.accessToken$.next(null);
          localStorage.clear();
          location.reload();
        }
        throw err;
      })
    );
  }

  private selectBaseUrl(url: string): string {
    if(url.includes('driver-registration/api/driver') || url.includes('media/api/images')) {
      return environment.config.baseDriverRegistrationUrl;
    } else {
      return environment.config.baseRegistrationUrl;
    }
  }

  private authHeader(): string {
    const token = this.tokenService.accessToken$.value;
    return  token ? `Bearer ${token}` : '';
  }
}
